<template>
    <div id="press">
        <PageTitle :title="title" :bg="headerImage" :template="'full'"/>
        <div class="logos">
            <img src="/images/press/1-Los-Angeles.png" class="" alt="">
            <img src="/images/press/2-Forbes.png" class="" alt="">
            <img src="/images/press/3-People.png" class="" alt="">
            <img src="/images/press/4-Fox-Business.png" class="" alt="">
            <img src="/images/press/5-The-Real-Deal.png" class="" alt="">
            <img src="/images/press/6-Yahoo.png" class="" alt="">
            <img src="/images/press/7-inman.png" class="" alt="">
            <img src="/images/press/8-Dirt.png" class="" alt="">
        </div>
        <div class="press-container">
            <div v-for="(item, index) in press" class="press-item" :key="index">
                <a class="overlay-container" :href="apiURL+item.PDF.url" target="_blank" rel="noopener noreferrer">
                    <img class="press-image" :src="apiURL+item.Image.url">
                    <div class="overlay">
                        <a class="btn" :href="apiURL+item.PDF.url" target="_blank" rel="noopener noreferrer">
                            View
                        </a>
                    </div>
                </a>
                <div v-if="item.Logo" class="press-logo-container">
                    <img class="press-logo" :src="apiURL+item.Logo.url">
                </div>
                <h3>
                    {{item.Description}}
                </h3>
            </div>
        </div>
        
    </div>
</template>

<script>
import PageTitle from "../components/PageTitle"

export default {
    name: "Press",
    components: {
        PageTitle,
    },
    data(){
        return{
            title: "Press",
            headerImage: '/images/header-press.jpeg',
            apiURL: 'https://strapi.uptowncreative.io'
        }
    },
    metaInfo: {
        title: 'Press',
        titleTemplate: '%s | Paul Salazar',
        meta: [{
            name: 'description',
            content: "Paul Salazar"
        }]
    },
    mounted:function(){
        if(!this.$store.getters.pressLoaded){
            this.$store.dispatch('getPress');
        }
    },
    methods:{

    },
    computed: {
        press:function(){
            return this.$store.getters.press;
        },
    },
}
</script>

<style lang="scss" scoped>
.logos{
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    align-items: center;
    padding: 30px;
    height: 180px;
    margin-top: -180px;
    background: rgba(21, 21, 21, 0.7);
    gap: 20px;
    img{
        width: 100%;
        max-width: 120px;
        margin: 0 auto;
    }
}

.press{
    text-align: center;
}
.press-container{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
    margin: 80px 60px;
    .press-image{
        width: 100%;
    }
    .press-logo-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100px;
        
    }
    .press-logo{
        max-width: 120px;
        max-height: 100%;
    }
}


.press-item{
    h3{
        text-align: left;
        color: #fff;
        font-weight: 300;
    }
}

.overlay-container{
  position: relative;
  display: block;
}

.overlay{
  transition: 1s ease;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  a{
    color:#fff;
    display: flex;
    align-items: center;
  }
}

.overlay-container:hover img {
  opacity: 0.3;
}

.overlay-container:hover .overlay {
  opacity: 0.8;
}

.btn{
  font-weight: 400;
  font-size: 17px;
  line-height: 27px;
  letter-spacing: 0.2em;

  color: #000000;
  text-decoration: none;
  text-transform: uppercase;
  border: 1px solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(to left, #E09F20, #E7C98F);
  padding: 16px 22px;
  padding-bottom: 10px;
}

@media (max-width: $tablet) {
    .logos{

        height: 300px;
        margin-top: -300px;
        grid-template-columns: repeat(4, 1fr);
    }

    .press-container{
        grid-template-columns: repeat(1, 1fr);
    }
}
</style>